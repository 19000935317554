<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="80"
    viewBox="0 0 70 80"
    fill="none"
    class="play-icon cursor-pointer"
  >
    <path
      d="M69.2966 40L34.8959 60L0.5 80V40V0L34.8959 20L69.2966 40Z"
      fill="url(#paint0_linear_235_879)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_235_879"
        x1="0.5"
        y1="40.0042"
        x2="69.2966"
        y2="40.0042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00EFAB" />
        <stop offset="1" stop-color="#00A7EF" />
      </linearGradient>
    </defs>
  </svg>
</template>
