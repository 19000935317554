import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import App from "./App.vue";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@/assets/sass/style.css";

// swiper
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';

// font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons"; 
library.add(fas, far, fab);

// Register the Play component globally
import Play from '@/components/Icons/Play.vue';
import CurrencyDropdown from '@/components/Common/CurrencyDropdown.vue';

const app = createApp(App);
const pinia = createPinia();

app.component("fa", FontAwesomeIcon);
app.component('Play', Play);
app.component('CurrencyDropdown', CurrencyDropdown);
app.use(pinia);
app.use(router);
app.mount("#app");
