<!-- eslint-disable prettier/prettier -->
<template>
  <!-- start header section -->
  <div class="header-wrap fixed-top">
    <nav class="navbar navbar-expand-xl">
      <div class="container-fluid">
        <!-- logo -->
        <router-link to="/" class="navbar-brand order-1" @click="mainPage">
          <img src="@/assets/img/logo.svg" alt="logo" class="img-fluid" />
        </router-link>

        <!-- toggle button -->
        <button class="navbar-toggler order-3 order-xl-2" type="button" :class="{ collapsed: !isExpanded }"
          @click="isExpanded = !isExpanded">
          <div class="navbar-toggler-icon line-1"></div>
          <div class="navbar-toggler-icon line-2"></div>
          <div class="navbar-toggler-icon line-3"></div>
        </button>

        <!-- nav links -->
        <div class="collapse navbar-collapse order-4 order-xl-3 justify-content-xxl-center collapse"
          id="navbarSupportedContent" :class="{ show: isExpanded }">
          <ul class="navbar-nav mb-2 mb-xl-0">
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span> About Us </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/about" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Who we are</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/about/know" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Know Us in 1 min</span>
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/why-trust-us"
                    class="dropdown-item"
                  >
                    Why Trust Us
                  </RouterLink>
                </li> -->
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/give-back"
                    class="dropdown-item"
                  >
                    Give Back
                  </RouterLink>
                </li> -->
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/media-center"
                    class="dropdown-item"
                  >
                    Media Center
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink @click="toggleNavbar" to="/about/careers" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Careers</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span> Funding Plans </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/funding-plans" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Daddy’s 2- Step Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/funding-plans/master" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Daddy’s 1 - Step Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/funding-plans/direct" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Daddy’s Direct Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/funding-plans/scaling" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Scaling Plan</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span> Free Resources </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/free-resources" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Tools</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/free-resources/symbols" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Symbols</span>
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/trading-magazine"
                    class="dropdown-item"
                  >
                    Trading Magazine
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink @click="toggleNavbar" to="/free-resources/economic-calendar" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Economic Calendar</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/free-resources/genius-leaderboard" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Genius Leaderboard</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/free-resources/certificates" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Certificates</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span> Get Help </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/faq" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>FAQs</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/customer-support" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Contact Us</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span> Education </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/education" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>FD Lessons</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/education/performance-coach" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Coach</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/education/blogs" class="dropdown-item">

                    <svg class="d-xl-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Blogs</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <RouterLink @click="toggleNavbar" to="/become-partner" class="nav-link">
                <span>Affiliate Program</span>
              </RouterLink>
            </li>
          </ul>

          <!-- btns group -->
          <div class="btns-group ms-auto order-2 order-xl-4 d-flex d-md-none flex-column">
            <router-link to="/free-dashboard-tour" class="common-btn w-100" aria-current="page">
              <span>Free Dashboard</span>
            </router-link>
            <router-link to="/login" class="common-outline-btn w-100" aria-current="page">
              <span>Sign Up</span>
            </router-link>
          </div>
        </div>

        <!-- btns group -->
        <div class="btns-group ms-auto order-2 order-xl-4 d-none d-md-flex mt-0">
          <!-- search button -->
          <button class="btn border-0 p-0 search-btn" data-bs-toggle="modal" data-bs-target="#searchModal">
            <fa icon="fa-magnifying-glass" class="text-white"></fa>
          </button>
          <!-- flag dropdown -->
          <div class="dropdown flag-dropdown">
            <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img :src="selectedCountry.flag" :alt="selectedCountry.language" />
              <fa icon="chevron-down"></fa>
            </a>
            <ul class="dropdown-menu" aria-labelledby="flagDropdown">
              <li v-for="option in countries" :key="option.language">
                <a class="dropdown-item flag-item" :class="option.language == selectedCountry.language ? 'active' : ''
                  " href="#" @click="selectLanguage(option)">
                  <img :src="option.flag" :alt="`${option.language} flag`" />
                  {{ option.language }}
                </a>
              </li>
            </ul>
          </div>

          <router-link to="/free-dashboard-tour" class="common-outline-btn dashboard-btn d-none d-sm-flex"
            aria-current="page">
            <span>Free Dashboard Tour</span>
            <fa icon="chevron-right"></fa>
          </router-link>
          <router-link to="/login" class="common-outline-btn user-btn" aria-current="page">
            <fa :icon="['far', 'user']" />
            <!-- <span class="d-none d-sm-block">Login</span> -->
          </router-link>
        </div>
      </div>
    </nav>
  </div>

  <!-- Search Modal -->
  <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content border-0">
        <div class="modal-header border-0">
          <input type="text" class="form-control border-0" placeholder="Search here......" />
          <button type="submit" class="common-btn submit-btn">
            <fa icon="fa fa-search"></fa>
          </button>
        </div>
        <div class="modal-body">
          <div class="title_box">
            <div class="d-flex justify-content-between">
              <h4>Posts</h4>
              <!-- <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> -->
            </div>
          </div>
          <div class="post-box">
            <img src="@/assets/img/bg/bg-gray.png" alt="bg-gray" class="img-fluid" />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
          <div class="post-box">
            <img src="@/assets/img/bg/bg-gray.png" alt="bg-gray" class="img-fluid" />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
          <div class="post-box border-0">
            <img src="@/assets/img/bg/bg-gray.png" alt="bg-gray" class="img-fluid" />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="text-primary me-auto w-fit me-auto my-0 border-0 fw-700" data-bs-dismiss="modal"
            aria-label="Close" @click="redirect()">
            View all results
          </button>
          <!-- <router-link
            data-bs-dismiss="modal"
            to="/search"
            class="text-primary me-auto w-fit me-auto my-0"
          >
            View all results
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  data() {
    return {
      isExpanded: false,
      selectedCountry: {
        language: "English",
        flag: require("@/assets/img/flag/english.svg"), // Use require here
      },
      countries: [
        {
          language: "English",
          flag: require("@/assets/img/flag/english.svg"),
        },
        {
          language: "Spanish",
          flag: require("@/assets/img/flag/spanish.svg"),
        },
        {
          language: "Chinese",
          flag: require("@/assets/img/flag/chines.svg"),
        },
        {
          language: "Indonesian",
          flag: require("@/assets/img/flag/indonesian.svg"),
        },
      ],
    };
  },
  methods: {
    selectLanguage(option) {
      this.selectedCountry = option;
    },
    redirect() {
      router.push("/search");
    },
    toggleNavbar() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler) {
          navbarToggler.click();
        }
      }
    },
    mainPage() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler && this.isExpanded) {
          navbarToggler.click();
        }
      }
    },
  },
};
</script>
