<!-- eslint-disable prettier/prettier -->
<template>
  <div class="container-fluid d-flex justify-content-center" :class="baseClass">
    <div class="big-block mb-130">
      <img
        src="@/assets/img/footer/building-logo.png"
        alt="building-logo"
        class="img-box img-fluid"
      />

      <div class="content-box">
        <h3 class="sub-heading main-head mb-30">
          Start Today to Become a Genius Trader Tomorrow.
        </h3>

        <div class="d-flex align-items-center flex-wrap gap-35 mb-20">
          <h3 class="sub-heading text-primary mb-0">Register</h3>
          <fa icon="chevron-right" class="svg-primary"></fa>
          <h3 class="sub-heading text-primary mb-0">Trade</h3>
          <fa icon="chevron-right" class="svg-primary"></fa>
          <h3 class="sub-heading text-primary mb-0">Earn & Grow</h3>
        </div>

        <router-link
          to="/login"
          class="common-btn w-fit border-0"
          aria-current="page"
        >
          <span>Start Now</span>
          <fa icon="chevron-right"></fa>
        </router-link>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="bg-black1">
      <div class="container-fluid w-1300">
        <div class="main-box">
          <!-- form box -->
          <div class="form-box">
            <h3 class="sub-heading text-white mb-20">Best kept secrets</h3>
            <p class="fs-12 fw-300 font-gotham mb-30 mw-371 lh-lg">
              Be the first to find out about the newest programs, workshops, and
              community activities - plus industry news, articles, and special
              reports. All delivered to your inbox!
            </p>

            <div class="position-relative mb-20 me-xxl-4">
              <input
                type="email"
                v-model="v$.email.$model"
                placeholder="Enter your email here...."
                class="form-control"
              />
              <img
                src="@/assets/img/footer/check-green.svg"
                alt="check-green"
                class="img-fuild check-green"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.email.$errors"
                :key="index"
              >
                <div class="text-danger fs-12">{{ error.$message }}</div>
              </div>
            </div>
            <button
              @click="submitForm"
              type="button"
              class="common-outline-btn d-none d-xl-flex"
            >
              <span>Subscribe</span>
            </button>
            <button
              @click="submitForm"
              type="button"
              class="common-btn w-100 d-xl-none mb-0"
            >
              <span>Subscribe</span>
            </button>

            <div class="align-items-center gap-20 security d-none d-xl-flex">
              <div>
                <router-link to="/" class="navbar-brand order-1">
                  <img
                    src="@/assets/img/logo.svg"
                    alt="logo"
                    class="img-fluid"
                  />
                </router-link>
              </div>
              <div class="d-flex align-items-center gap-3">
                <img
                  src="@/assets/img/footer/exclude.svg"
                  alt="exclude"
                  class="img-fluid"
                />
                <div class="d-flex flex-column justify-content-between h-100">
                  <p class="fs-12 fw-300 font-gotham mb-18">USED BY</p>
                  <p class="fs-12 fw-700 mb-0">2 Million+ Users</p>
                </div>
              </div>
            </div>
          </div>
          <!-- links box -->
          <div class="links-box">
            <!-- About us links group  -->
            <div class="links-group">
              <div class="links-title font-gotham">About us</div>
              <ul>
                <li>
                  <RouterLink to="/about"> Who we are </RouterLink>
                </li>
                <li>
                  <RouterLink to="/about/know"> Know us in 1 min </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink to="/about/why-trust-us">
                    Why Trust Us
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/about/give-back"> Give Back </RouterLink>
                </li>
                <li>
                  <RouterLink to="/about/media-center">
                    Media Center
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink to="/about/careers"> Careers </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Funding Plans links group  -->
            <div class="links-group">
              <div class="links-title font-gotham">Funding Plans</div>
              <ul>
                <li>
                  <RouterLink to="/funding-plans">
                    Daddy’s <br>
                    2 - Step Challenge
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/funding-plans/master">
                    Daddy’s <br>
                    1 - Step Challenge
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/funding-plans/direct">
                    Daddy’s <br>
                    Direct Challenge
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/funding-plans/scaling">
                    Scaling Plan
                  </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Free Resources links group  -->
            <div class="links-group">
              <div class="links-title font-gotham">Free Resources</div>
              <ul>
                <li>
                  <RouterLink to="/free-resources"> Trading Tools </RouterLink>
                </li>
                <li>
                  <RouterLink to="/free-resources/symbols">
                    Symbols
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink to="/free-resources/trading-magazine">
                    Trading Magazine
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink to="/free-resources/economic-calendar">
                    Economic Calendar
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/free-resources/genius-leaderboard">
                    Genius Leaderboard
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/free-resources/certificates">
                    Certificates
                  </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Get help links group  -->
            <div class="links-group">
              <div class="links-title font-gotham">Get help</div>
              <ul>
                <li><router-link to="/faq">FAQs</router-link></li>
                <li>
                  <router-link to="/customer-support">Contact us</router-link>
                </li>
              </ul>
            </div>
            <!-- Education links group  -->
            <div class="links-group">
              <div class="links-title font-gotham">Education</div>
              <ul>
                <li>
                  <RouterLink to="/education"> Trading Coach </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink to="/education/performance-coach">
                    Performance Coach
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink to="/education/blogs"> Blog </RouterLink>
                </li>
              </ul>
            </div>
            <!-- Become a Partner links group  -->
            <div class="links-group">
              <!-- <div class="links-title font-gotham"> -->
              <RouterLink
                to="/become-partner"
                class="links-title font-gotham link"
              >
                Affiliate Program
              </RouterLink>
              <!-- </div> -->
              <ul>
                <!-- <li>
                  <RouterLink to="/become-partner">
                    Affiliate Program
                  </RouterLink>
                </li> -->
                <!-- <li>
                  <RouterLink to="/become-partner/affiliate-contest">
                    Affiliate Contest
                  </RouterLink>
                </li> 
                <li>
                  <RouterLink to="/become-partner/payment-partner">
                    Payment Partner
                  </RouterLink>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <div class="bottom-box d-none d-xl-flex">
          <div class="mw-884 mb-0 order-1 order-lg-0">
            <p class="fs-12 fw-500 font-gotham">
              <b>Risk Warning:</b> Leveraged products such as Forex and CFD
              trading are complex instruments with a high risk of losing money.
              You must be aware of the risks and be willing to accept them to
              trade in the markets.
            </p>
            <p class="fs-12 fw-500 font-gotham mb-0">
              <b>Disclaimer:</b> Funding Daddy®does not provide services for
              residents of any country where such distribution or use would be
              contrary to local law or regulation. You must be 18 years old, or
              of legal age as determined in your country. Our services are
              designed to evaluate an individual's trading competencies, and
              outcomes depend on the individual’s proficiency and adherence to
              our trading program guidelines and goals. Funding Daddy®is not a
              broker and thus doesn't receive deposits from its clients.
              Liquidity providers facilitate the technical setup and price feed
              data for Funding Daddy's platforms.
            </p>
          </div>

          <div class="mw-560 mx-auto me-lg-0 order-0 order-lg-1">
            <h3 class="text-primary sub-heading mb-40 text-center">
              Out Trusted Partners
            </h3>
            <img
              src="@/assets/img/footer/partners.svg"
              alt="partners"
              class="img-fluid mx-auto d-block "
            />
          </div>
        </div>

        <div class="copyright-box">
          <p class="fs-12 font-gotham text-start mb-0">
            © 2024 Funding Daddy All rights reserved.
          </p>
          <div class="copyright-links justify-content-lg-between gap-30">
            <router-link to="/privacy-policy" class="fs-12 font-gotham" aria-current="page">
              <span>Privacy Policy</span>
            </router-link>
            <router-link to="/terms-and-conditions" class="fs-12 font-gotham" aria-current="page">
              <span>Terms & Conditions</span>
            </router-link>
            <router-link to="/cookies" class="fs-12 font-gotham" aria-current="page">
              <span>Cookies</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

export default {
  name: "footer",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: "",
    };
  },
  props: {
    baseClass: {
      type: String,
      default: "",
    },
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(
          "* Please type Your Email Address.",
          required
        ),
        email,
      },
    };
  },
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();

      if (isFormCorrect == true) {
        console.log(this.email);
      }
    },
  },
};
</script>
