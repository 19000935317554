<template>
  <div
    v-if="
      $route.name != 'login' &&
      $route.name != 'sign-up' &&
      $route.name != 'confirmation-code' &&
      $route.name != 'free-dashboard-tour' &&
      $route.name != 'new-password' &&
      $route.name != 'register' &&
      $route.name != 'stepverification'
    "
  >
    <Header />
    <div class="page-content">
      <router-view />
    </div>
    <Footer
      :baseClass="
        $route.name === 'free-resources-trading-magazine'
          ? 'bg-primary'
          : $route.name == 'education-blogs' ||
            $route.name == 'free-resources-trading-applications' || 
            $route.name == 'free-resources-economic-calendar' || 
            $route.name == 'free-resources-genius-leaderboard' || 
            $route.name == 'free-resources-certificates' || 
            $route.name == 'faq' || 
            $route.name == 'search' || 
            $route.name == 'free-resources-symbols' 
          ? 'bg-background'
          : ''
      "
    />
  </div>

  <router-view v-else />
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
