import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // Home route remains the same
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  // Login route remains the same
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  
  {
    path: "/confirmation-code",
    name: "confirmation-code",
    component: () => import("@/views/Auth/ConfirmationCode.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/Auth/SignUp.vue"),
  },
  {
    path: "/free-dashboard-tour",
    name: "free-dashboard-tour",
    component: () => import("@/views/Auth/DashboardTour.vue"),
  },

  {
    path: "/new-password",
    name: "new-password",
    component: () => import("@/views/Auth/NewPassword.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/stepverification",
    name: "stepverification",
    component: () => import("@/views/Auth/StepVerification.vue"),
  },

  // Search page routes
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/Search.vue"),
  },
  // About Us dropdown routes
  {
    path: "/about",
    children: [
      {
        path: "", // This represents the "/about" path as it's the default child
        name: "about",
        component: () => import("@/views/About/About.vue"),
      },
      {
        path: "know",
        name: "know",
        component: () => import("@/views/About/Know.vue"),
      },
      {
        path: "why-trust-us",
        name: "why-trust-us",
        component: () => import("@/views/About/WhyTrustUs.vue"),
      },
      {
        path: "give-back",
        name: "give-back",
        component: () => import("@/views/About/GiveBack.vue"),
      },
      {
        path: "media-center",
        name: "media-center",
        component: () => import("@/views/About/MediaCenter.vue"),
      },
      {
        path: "careers",
        name: "careers",
        component: () => import("@/views/About/Careers.vue"),
      },
    ],
  },

  // Funding Plans dropdown routes
  {
    path: "/funding-plans",
    children: [
      {
        path: "",
        name: "funding-plans-starter",
        component: () => import("@/views/FundingPlans/Starter.vue"),
      },
      {
        path: "master",
        name: "funding-plans-master",
        component: () => import("@/views/FundingPlans/Master.vue"),
      },
      {
        path: "direct",
        name: "funding-plans-direct",
        component: () => import("@/views/FundingPlans/Direct.vue"),
      },

      {
        path: "amplifier",
        name: "funding-plans-amplifier",
        component: () => import("@/views/FundingPlans/Amplifier.vue"),
      },
      {
        path: "scaling",
        name: "funding-plans-scaling",
        component: () => import("@/views/FundingPlans/Scaling.vue"),
      },
    ],
  },

  // Free Resources dropdown routes
  {
    path: "/free-resources",
    children: [
      {
        path: "",
        name: "free-resources-trading-applications",
        component: () =>
          import("@/views/FreeResources/TradingApplications.vue"),
      },
      {
        path: "symbols",
        name: "free-resources-symbols",
        component: () => import("@/views/FreeResources/Symbols.vue"),
      },
      {
        path: "trading-magazine",
        name: "free-resources-trading-magazine",
        component: () => import("@/views/FreeResources/TradingMagazine.vue"),
      },
      {
        path: "economic-calendar",
        name: "free-resources-economic-calendar",
        component: () => import("@/views/FreeResources/EconomicCalendar.vue"),
      },
      {
        path: "genius-leaderboard",
        name: "free-resources-genius-leaderboard",
        component: () => import("@/views/FreeResources/GeniusLeaderboard.vue"),
      },
      {
        path: "certificates",
        name: "free-resources-certificates",
        component: () => import("@/views/FreeResources/Certificates.vue"),
      },
    ],
  },

  // FAQ page routes
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/Faq.vue"),
  },

  // Customer support page routes
  {
    path: "/customer-support",
    name: "customer-support",
    component: () => import("@/views/CustomerSupport.vue"),
  },

  // Education dropdown routes
  {
    path: "/education",
    children: [
      {
        path: "",
        name: "education-academy",
        component: () => import("@/views/Education/Academy.vue"),
      },
      {
        path: "details",
        name: "education-academy-details",
        component: () => import("@/views/Education/Academy-details.vue"),
      },
      {
        path: "performance-coach",
        name: "education-performance-coach",
        component: () => import("@/views/Education/PerformanceCoach.vue"),
      },
      {
        path: "blogs",
        name: "education-blogs",
        component: () => import("@/views/Education/Blogs.vue"),
      },
      {
        path: "blog-details",
        name: "education-blog-details",
        component: () => import("@/views/Education/BlogsCategories.vue"),
      },
      {
        path: "blog-second",
        name: "education-blog-second",
        component: () => import("@/views/Education/BlogSecond.vue"),
      },
    ],
  },

  // Become Partner dropdown routes
  {
    path: "/become-partner",
    children: [
      {
        path: "",
        name: "become-partner-affiliate-program",
        component: () => import("@/views/BecomePartner/AffiliateProgram.vue"),
      },
      {
        path: "affiliate-contest",
        name: "become-partner-affiliate-contest",
        component: () => import("@/views/BecomePartner/AffiliateContest.vue"),
      },
      {
        path: "payment-partner",
        name: "become-partner-payment-partner",
        component: () => import("@/views/BecomePartner/PaymentPartner.vue"),
      },
    ],
  },

  // Rules page routes
  {
    path: "/rules",
    name: "rules",
    component: () => import("@/views/Rules.vue"),
  },

  // Rules page routes
  {
    path: "/competition",
    name: "Competition",
    component: () => import("@/views/Competition.vue"),
  },

  // Page not found route
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "nav-link",
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page when navigating to a new route
  window.scrollTo(0, 0);
  next();
});

export default router;
